import "@fontsource/quicksand/latin-400.css"
import "@fontsource/quicksand/latin-500.css"
import "@fontsource/quicksand/latin-600.css"
import "@fontsource/quicksand/latin-700.css"

require("./src/styles/index.scss")
require("./src/styles/bootstrap.scss")

// without the below the position is not reset when navigating to new page
export const onRouteUpdate = () => {
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0)
  }
}

// export { default as wrapRootElement } from './src/state/ReduxWrapper';
